body {
  background-color: #2a5ca6 !important;
}

.main-row {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-evenly;
}

.terms-row {
  display: flex;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
}

.grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.dash-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
}
