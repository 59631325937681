body {
    background-color: #2A5CA6 !important;
}

h1 {
    font-size: 70px !important;
}

.top-container {
    background-color: #2A5CA6;
}

.header-section {
    padding: 20px;
    padding-bottom: 0px;
}

.landing-image {
    width: 200px;
    height: auto;
}

.hero-section {
    padding: 20px;
    padding-top: 0px;
}

.hero-title {
    margin-top:20px;
}

.downloads {
    padding: 20px;
}

.download-apple {
    width: 200px;
    height: auto;
}

.download-android {
    width: 250px;
    height: auto;
}

.download-box {
    padding: 20px;
    padding-bottom: 0px;
}

.footer-section {
    background-color: #464D54;
    padding: 20px;
}

.hero-box {
    padding: 20px;
}

.hero-image {
    height: auto;
}

.footer-box {
    background-color: #888888;
    padding: 20px;
}