body {
    background-color: #2A5CA6 !important;
}

h1 {
    font-size: 70px !important;
}

.logo-image {
    width: 160px;
    height: auto;
}

.login-section {
    padding-left: 30px;
    padding-right: 30px;
}

.login-box {
    max-width: 500px;
    background-color: #EEEEF1;
    padding: 40px;
    border-radius: 10px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
}

.login-title {
    color: #464D54;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-left: 1px;
}

.login-checkbox {
    margin-top: 20px;
}

.login-button {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 20px;
}

.alert-box {
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 12px;
}

.forgot-box {
    margin-top: 30px;
}

.bg-check {
    background-color: yellow;
}

.build-image {
    height: auto;
}