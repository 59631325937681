.admin-header {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
}

.admin-logo {
  width: 200px;
  height: auto;
}

.user-button {
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #eeeef1;
}
